import React, { useRef } from "react";
import Logo from '../images/Logo.png'
import { Fade } from "react-reveal";
import emailjs from '@emailjs/browser'

//Styles
import '../Styles/Home/Navbar.css'
import '../Styles/Home/sec-1.css'
import '../Styles/Home/sec-2.css'
import '../Styles/Home/sec-3.css'
import '../Styles/Home/sec-4.css'
import '../Styles/Home/sec-5.css'
import '../Styles/Home/sec-6.css'

//Images
import iphone from '../images/iphone.png'
import ios from '../images/ios.png'
import android from '../images/android.png'
import website from '../images/website.png'
import { FaMailBulk, FaPhone, FaLocationArrow, FaBars, FaInstagram, FaLinkedin, FaTiktok, FaTimes } from "react-icons/fa";
import Arrow1 from '../images/iphoneback.png'
import mad from '../images/mad logo2.png'
import fighter from '../images/Asset 10@4x-8.png'

//icons

import sec from '../images/security.png'
import cloud from '../images/database.png'
import ios2 from '../images/ios1.png'
import android2 from '../images/android2.png'
import web from '../images/web.png'
import card from '../images/card.png'
import Arrow2 from '../images/Arrow.png'

const Home = () => {

    const form = useRef()

    const navRef = useRef()

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav")
    }

    const Whyusbtn = () => {
        const section = document.getElementById('sec-4')

        section.scrollIntoView({behavior: 'smooth'})
        showNavbar()
    }

    const contactbtn = () => {
        const section = document.getElementById('sec-5')

        section.scrollIntoView({behavior: 'smooth'})
        showNavbar()
    }

    const Whyusbtn2 = () => {
        const section = document.getElementById('sec-4')

        section.scrollIntoView({behavior: 'smooth'})
    }

    const Projectsbtn = () =>{
        const section = document.getElementById('sec-3')

        section.scrollIntoView({behavior: 'smooth'})
        showNavbar()
    }

    const Reabmorebtn = () =>{
        const section = document.getElementById('sec-2')

        section.scrollIntoView({behavior: 'smooth'})
    }

    const sendEmail = (e) => {
        e.preventDefault();
      
        emailjs.sendForm(process.env.REACT_APP_SID_ID, process.env.REACT_APP_TID_ID, form.current, process.env.REACT_APP_UID_ID)
          .then((result) => {
              console.log(result.text);
              alert("thank you")
          }, (error) => {
              console.log(error.text);
              alert("sorry! try again")
          });
      };


    return(
        <React.Fragment>

            <header>
            <img src={Logo} alt='header logo' onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>

            <view className='space'/>

            <nav className='navbar' ref={navRef}>
                <div className='innav'>
                <img src={Logo} alt='header logo' onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                <button onClick={showNavbar} className='navbtn navhide'>
                    <FaTimes color="#00D4C8"/>
                </button>
                </div>
                <button onClick={Whyusbtn}>למה אנחנו</button>
                <button onClick={Projectsbtn}>פרויקטים</button>
                <button onClick={contactbtn}>צור קשר</button>
            </nav>
            <button onClick={showNavbar} className='navbtn navshow'>
                <FaBars color="#00D4C8"/>
            </button>
        </header>

            <section className="sec-1">

                <Fade top duration={2500}>
                
                <div className="block1">
                    <h1>DevStation.pro</h1>
                    <p>Unleashing the power of code, one innovation at a time. Welcome to Devstation.pro, where software dreams come to life. 🚀💻✨</p>
                </div>
                </Fade>

                <Fade right duration={2500}>

                <div className="div2">

                <div className="block2">

                    <img src={website} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                    <img src={ios} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                    <img src={android} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>
                    
                </div> 

                <button onClick={() => Reabmorebtn()}>קרא עוד</button>

                </div>

                </Fade>
                
            </section>

            <section className="sec-2" id="sec-2">

            <Fade top distance="20%">

                <div className="block3">

                    <div className="imagecontent">

                    </div>
                    <img src={iphone} loading="lazy" onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>

                </div>

                </Fade>

                <Fade left distance="20%">

                <div className="block4">

                    <h1>עלינו</h1>

                    <p>הוא סטארט-אפ בעל חשיבה קדימה לפיתוח אתרים ואפליקציות המוקדש ליצירת פתרונות דיגיטליים איכותיים, ידידותיים למשתמש ומושכים חזותית</p>

                    <div className="socialcontent">

                        <div className="info">
                        <a href="mailto:adi@devstation.pro" target="_blank"> <FaMailBulk size={30} color="#fff"/> adi@devstation.pro</a>
                        <a href="tel:+972532348704" target="_blank"> <FaPhone size={30} color="#fff"/> +972532348704</a>
                        <a href="https://www.waze.com/live-map/directions?navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location&to=ll.33.26719105%2C35.76725721" target="_blank"> <FaLocationArrow size={30} color="#fff"/> Majdal shams</a>
                        </div>

                        <div className="socialmedia">
                            <a href="https://www.instagram.com/dev.station.pro/" target="_blank"><FaInstagram size={40} color="#fff"/></a>
                            <a href="https://www.linkedin.com/in/devstation-pro-%F0%9F%A7%91%E2%80%8D%F0%9F%92%BB-471b8b20a/" target="_blank"><FaLinkedin size={40} color="#fff"/></a>
                        </div>

                    </div>

                    <button onClick={Whyusbtn2}>למה אנחנו</button>

                    <img src={Arrow1} onContextMenu={(e) => {e.preventDefault()}} onDragStart={(e) => {e.preventDefault()}}/>

                </div>

                </Fade>

            </section>

            <section className="sec-3" id="sec-3">

                <Fade top distance="20%">

                <h1>פרויקטים</h1>

                <div className="procon">
    
                    <div>

                        <h1>fighter-vision</h1>

                        <img src={fighter}/>

                        <a href="https://fighter-vision.com/" target="blank">אתר אינטרנט</a>
                        
                    </div>

                </div>

                </Fade>

            </section>

            <section className="sec-4" id="sec-4">

            <Fade top distance="20%">

                <div className="head">
                    <h1>למה אנחנו</h1>
                </div>

                <div className="icons">

                    <img src={sec}/>
                    <img src={cloud}/>
                    <img src={ios2}/>
                    <img src={android2}/>
                    <img src={web}/>
                    <img src={card}/>

                </div>

                <p>שליטה מרובת פלטפורמות: מאנדרואיד , אינטרנט, אנו מצטיינים בכל הזירות ●</p>
                <p>מצוינות מותאמת אישית: התאמת פתרונות לצרכים הייחודיים שלך היא המומחיות שלנו ●</p>
                <p>חדשנות מתקדמת: הישאר קדימה עם הטרנדים הטכנולוגיים העדכניים ביותר בכל פלטפורמה ●</p>
                <p>שותפות בשיתוף פעולה: הקלט שלך מעצב את המסע מהרעיון לביצוע ●</p>
                <p>איכות בכל רחבי הלוח: אמינות, אבטחה וידידותיות למשתמש הם סימני ההיכר שלנו ●</p>
                <p>אספקה דייקנית: אנו מכבדים את זמנך ומספקים לפי לוח הזמנים ●</p>
                <p>תמיכה מתמשכת: לאחר ההשקה, אנו מבטיחים שהנכסים הדיגיטליים שלך ימשיכו לזרוח ●</p>

                <img src={Arrow2} className="arrow2"/>

                </Fade>

            </section>

            <section className="sec-5" id="sec-5">

                <h1>צור קשר</h1>

                <form ref={form}>

                <div className="contactinputs">

                    <Fade top distance="20%">

                    <input placeholder="שם" type="Name" name="name"/>
                    <input placeholder="טלפון" type="phone" name="phone"/>
                    <input placeholder="אימייל" type="email" name="email"/>
                    <textarea placeholder="הודעה" rows="4" cols="50" name="message"></textarea>

                    </Fade>

                </div>

                </form>

                <button onClick={sendEmail}>שלח</button>

            </section>

            <section className="sec-6">

                <h1>DevStation.pro</h1>

                <p>Copyright 2024 - devstation.pro</p>


            </section>

        </React.Fragment>
    )

}

export default Home